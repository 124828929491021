import React, { useEffect, useState } from 'react';
import Throbber from '../throbber';
import PollsManager from '../../managers/Polls';
import AccountManager from '../../managers/Account';

const PollsEntry = ({ poll, onPollSubmission, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pollConfiguration, setPollConfiguration] = useState(null);
  const [pollAnswers, setPollAnswers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState({});
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const makeChoice = (choice, e) => {
    if (e) {
      e.preventDefault();
    }
    setShowErrorMsg(false);
    if (selectedChoice && selectedChoice.id === choice.id) {
      setSelectedChoice({});
    } else {
      setSelectedChoice(choice);
    }
  };

  const submitPoll = async () => {
    setIsLoading(true);
    const payload = {
      pollId: poll.pollId,
      selectedPollOptionId: selectedChoice.id,
      answerText: selectedChoice.description,
    };
    try {
      const pollResponse = await PollsManager.submitPoll(AccountManager.getToken(), payload);
      if (pollResponse && pollResponse.success) {
        setIsLoading(false);
        setIsSuccess(true);
        localStorage.setItem('ETHO_POLL_' + poll.utilityId, true);
        onPollSubmission();
      } else {
        throw new Error(pollResponse.message || 'Error submitting poll. Please try again.');
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorMsg(true);
      setErrorMsg(error.message);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    onClose();
  };

  const checkForExistingPollSubmission = (utilityId) => {
    const pollSubmission = localStorage.getItem('ETHO_POLL_' + utilityId);
    if (pollSubmission) {
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    if (poll) {
      checkForExistingPollSubmission(poll.utilityId);
      setPollConfiguration(poll.pollConfiguration);
      setPollAnswers(poll.pollOptions);
    }
  }, [poll]);

  return (
    <div className="contest-modal">
      {isLoading
      && <Throbber throbberText="Submitting response..." />}
      <div className="polls-modal__polls-header">
        <div className="contest-modal__header">
          {poll.title}
        </div>
        {pollConfiguration && pollConfiguration.pointsAwarded > 0 && (
          <div className="contest-modal__prize-description">
            {isSuccess ? (
              <span className="contest-modal__points-completed">Completed</span>
            ) : (
              <span>Earn</span>
            )}
            {': '}
            <span className="contest-modal__points-amount">
              {pollConfiguration.pointsAwarded}
              {' '}
              points
            </span>
          </div>
        )}
      </div>
      {!isSuccess && (
      <form>
        <div className="polls-modal__polls-body">
          <div className="polls-modal__polls-body--inner">
            <div className="polls-modal__polls-body--description">
              {poll.description}
            </div>
            <div className="polls-modal__question">
              <div className={poll.pollOptions[0].pollOptionType === 2 ? 'polls-modal__image--choices' : 'polls-modal__text--choices'}>
                { pollAnswers.length > 0 && (
                  pollAnswers.map((choice) => (
                    <div
                        key={choice.id}
                        className="polls-modal__option--choice"
                      onClick={() => makeChoice(choice)}>
                      <div>
                        {choice.pollOptionLinkUrl
                        && <div><img src={choice.pollOptionLinkUrl} alt="choice 1" /></div>}
                        <div className="polls-modal__option--choice-inner">
                          <input
                            type="radio"
                            value={choice.id}
                            name={'choice' + choice.id}
                            id={'choice' + choice.id}
                            checked={choice.id === selectedChoice.id}
                            readOnly />
                          {' '}
                          {choice.description}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            
          </div>
        </div>
      </form>
      )}
      {isSuccess && (
      <div className="contest-modal__success">
        <img src="https://assets.heyethos.com/ethos/v2/ui/icon-success.png" alt="Poll success" />
        <div className="poll-modal__success">{poll.completionMessage}</div>
      </div>
      )}
      <div className="polls-modal__polls-footer">
        {showErrorMsg && (
        <div className="polls-modal__message -error">
          {errorMsg || 'Error submitting poll. Please try again.'}
        </div>
        )}

        <div className="polls-modal__cta">
          {isSuccess ? (
            <button
                type="button"
                className="button-collection"
                onClick={(e) => closeModal(e)}>
              Done
            </button>
          ) : (
            <button
                type="button"
                className="button-collection"
                onClick={() => submitPoll()}
                disabled={selectedChoice && !selectedChoice.id}>
              Submit
            </button>
          )}
        </div>
      </div>

    </div>
  );
};
export default PollsEntry;
